import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";

import Home from "./pages/home";
import Back2School from "./pages/back2schol";
import AboutSection from "./pages/about";
import Signleproduct from "./pages/singleproduct";
import WaitlistSection from "./pages/waitlist";
// import Order from "./pages/order"
// import Orderdetails from "./components/order-tracker"

//admin
import Signup from "./admin/auth/signup";
import Login from "./admin/auth/login";

import AdminProductForm from "./admin/dashboard/component/pages/addproduct";
import OrderBulk from "./admin/dashboard/component/pages/viewproduct";
import Sidebar from "./admin/dashboard/component/sidebar";






function App() {

  const location = useLocation();

  // Paths where the Sidebar and Navbar should not be shown
  const excludedPaths = ["/", "/admin/auth/signup", "/admin/auth/login", "/Back2School","/About","/product", "/waitlist", "/homepage"]; // Exclude paths like login and signup
  
  const showNavbarAndSidebar = !excludedPaths.includes(location.pathname);

  return (
    <div className="flex min-h-screen w-fit">
      {/* Conditionally render Sidebar and Navbar */}
      {showNavbarAndSidebar && <Sidebar />}
      <div className={`flex-1 ${showNavbarAndSidebar ? 'p-6' : ''}`}>
      <Routes>
        <Route path="/homepage" element={<Home />} />   
        <Route path="/Back2School" element={<Back2School />} />   
        <Route path="/About" element={<AboutSection />} /> 
        <Route path="/product" element={<Signleproduct />} /> 
        <Route path="/" element={<WaitlistSection />} />   
        {/* <Route path="/order" element={<Order />} />   */}
    
        {/* <Route path="/order-details" element={<Orderdetails />} /> */}

        
        <Route path="/admin/auth/signup" element={<Signup />} /> 
        <Route path="/admin/auth/login" element={<Login />} />  

        <Route path="/admin/dashboard" element={<AdminProductForm />} />  
        <Route path="/admin/viewclothe" element={<OrderBulk />} />  
      </Routes>


    </div>
  </div>
  );
}

export default App;
