import React from 'react';
import rizzlogo from "../public/rizz.svg";
import auralogo from "../public/aura.svg";
import inifinityimg from "../public/infinity-smybol_prev_ui.png";
import { Link } from 'react-router-dom';

import persons from "../public/persons.svg"
import wings from "../public/feaders.svg"
import Navbar from '../components/navbar';
import Footer from "../components/footer"
import ProductSection from '../components/product-homepage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';


import firstimg from "../public/header-img.jpg"
import frame2 from "../public/frame2.jpeg"
import secondimg from "../public/sport-nogeriq.jpg"
import thirdimg from "../public/frame5.jpeg"
import frame3 from "../public/frame8.jpeg"
import fourthimg from "../public/f1edd66695b24d79dd36446cdfd90ebd.jpeg"

import shirt from "../public/collection1_1.svg"
import jean from "../public/collection1_2.png"
import hoode1 from "../public/collection1_3.png"
import hoode2 from "../public/collection1_5.png"

import collection11 from "../public/collection2_1.png"
import collection12 from "../public/collection2_2.png"
import collection13 from "../public/collection2_3.png"
import collection15 from "../public/collection2_5.png"


import collection21 from "../public/collection3_1.png"
import collection22 from "../public/collection3_2.png"
import collection23 from "../public/collection3_3.png"
import collection25 from "../public/collection3_5.png"

import arrowup from "../public/arrow-up-right.svg"

const products = [
  { id: 1, title: "Aura Hoodie", price: "$170", img: shirt },
  { id: 2, title: "Aura Hoodie", price: "$170", img: jean },
  { id: 3, title: "Aura Hoodie", price: "$170", img: hoode1 },
  { id: 4, title: "Aura Hoodie", price: "$170", img: hoode2 },
  // Add more products as needed
];

const products2 = [
  { id: 1, title: "Aura Hoodie", price: "$170", img: collection21 },
  { id: 2, title: "Aura Hoodie", price: "$170", img: collection22 },
  { id: 3, title: "Aura Hoodie", price: "$170", img: collection23 },
  { id: 4, title: "Aura Hoodie", price: "$170", img: collection25 },
  // Add more products as needed
];

const products3 = [
  { id: 1, title: "Aura Hoodie", price: "$170", img: collection11 },
  { id: 2, title: "Aura Hoodie", price: "$170", img: collection12 },
  { id: 3, title: "Aura Hoodie", price: "$170", img: collection13 },
  { id: 4, title: "Aura Hoodie", price: "$170", img: collection15 },
  // Add more products as needed
];

const HumanDiagrams = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  
  return (
  <>
  <Navbar />
  

    <section className="relative w-full h-[700px] max-sm:h-[1000px] max-sm:bg-cover bg-center bg flex justify-center items-center" 
             style={{ backgroundImage: `url(${firstimg})` }}>

     <div className="absolute inset-0 opacity-35 bg-black"></div>
      
      <div className="relative z-10 text-white items-center justify-center flex max-sm:items-center max-sm:justify-center flex-col h-full md:p-16 max-w-full">
        <h1 className="text-[64px] max-sm:text-[50px] font-bold mb-4">DEI ELECTI</h1>
        <p className="text-[32px] mb-6 text-center">A gallery for the exceptiional.</p>

        <a 
          href="/shop" 
          className="inline-flex  underline  items-center text-white hover:underline text-[32px] font-medium">
          Shop Dei Electi
          <img src={arrowup} className="ml-2" />
        </a>
      </div>
    </section>

    <section className="relative w-screen h-[500px] max-sm:h-[1000px] max-sm:bg-cover bg-center  flex justify-center items-center mt-3" 
             style={{ backgroundImage: `url(${frame2})` }}>
      
      <div className="absolute inset-0 opacity-35 bg-black"></div> {/* Dark Overlay */}

      <div className="relative z-10 text-white items-center justify-center flex max-sm:items-center max-sm:justify-center flex-col h-full md:p-16 max-w-full">
        <h1 className="text-[64px] max-sm:text-[39px] font-bold mb-4 uppercase text-center">Ye’s salvation</h1>
        <p className="text-[32px] mb-6 text-center">Redefine your wardrobe, reclaim your identity.</p>

        <a 
          href="/shop" 
          className="inline-flex  underline  items-center text-white hover:underline text-[32px] font-medium">
          Shop Collection
          <img src={arrowup} className="ml-2" />
        </a>
      </div>
    </section>

    <section className="my-8 p-4">
      <div className=" text-center mb-6">
        <h2 className="text-2xl font-bold">Ye’s Salvation</h2>
        <Link to="/Back2School" className=" text-lg font-medium text-black underline">
        Collection Preview
          {/* <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2" /> */}
        </Link>
      </div>
      
    
       <div className=''>
        <ProductSection />
       </div>
    </section>

    <section className="relative w-screen h-[500px] max-sm:h-[1000px] max-sm:bg-cover bg-cover" 
             style={{ backgroundImage: `url(${secondimg})` }}>
       <div className="absolute inset-0  opacity-35 bg-black"></div> {/* Dark Overlay */}
      
      <div className="relative z-10 items-center justify-center text-white flex flex-col h-full p-8 md:p-16 max-w-full">
        <h1 className="text-[64px] max-sm:text-[50px] font-bold mb-4 uppercase">sportsman</h1>
        <p className="text-[32px] mb-6 text-center">Champion your style, on and off the field. </p>

        <a 
          href="/shop" 
          className="inline-flex max-sm:underline items-center text-white underline text-[32px] font-medium">
          Shop Collection
          <img src={arrowup} className="ml-2" />
        </a>
        </div>
    </section>

   <section className="my-8 p-4">
      <div className=" text-center mb-6">
        <h2 className="text-2xl font-bold">Sportsman</h2>
        <Link to="/Back2School" className=" text-lg font-medium text-black underline">
        Collection Preview
          {/* <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2" /> */}
        </Link>
      </div>
      
    
      <div className=''>
        <ProductSection />
       </div>
    </section>

    <section className="relative w-screen h-[500px] max-sm:h-[1000px] max-sm:bg-cover bg-center" 
             style={{ backgroundImage: `url(${thirdimg})` }}>
       <div className="absolute inset-0  opacity-30 bg-black"></div> {/* Dark Overlay */}
      
      <div className="relative z-10 items-center justify-center text-white flex flex-col h-full p-8 md:p-16 max-w-full">
        <h1 className="text-[64px] max-sm:text-[50px] font-bold mb-4 uppercase">omo naija</h1>
        <p className="text-[32px] mb-6">Wear your heritage. </p>

        <a 
          href="/shop" 
          className="inline-flex max-sm:underline items-center text-white underline text-[32px] font-medium">
          Shop Collection
          <img src={arrowup} className="ml-2" />
        </a>
        </div>
    </section>

    <section className="my-8 p-4">
      <div className=" text-center mb-6">
        <h2 className="text-2xl font-bold">Omo Naija</h2>
        <Link to="/Back2School" className=" text-lg font-medium text-black underline">
        Collection Preview
          {/* <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2" /> */}
        </Link>
      </div>
      
    
      <div className=''>
        <ProductSection />
       </div>
    </section>

    <section className="relative w-screen mt-10 h-[500px] max-sm:h-[1000px] max-sm:bg-cover bg-cover bg-no-repeat" 
             style={{ backgroundImage: `url(${fourthimg})` }}>
       <div className="absolute inset-0  opacity-35 bg-black"></div> {/* Dark Overlay */}
      
      <div className="relative z-10 items-center justify-center text-white flex flex-col h-full p-8 md:p-16 max-w-full">
        <h1 className="text-[64px] max-sm:text-[39px] font-bold mb-4 uppercase">cold blooded</h1>
        <p className="text-[32px] text-center mb-6">Shed your skin, embrace the bold </p>

        <a 
          href="/shop" 
          className="inline-flex max-sm:underline items-center text-white underline text-[32px] font-medium">
          Shop Collection
          <img src={arrowup} className="ml-2" />
        </a>
        </div>
    </section>

    <section className="my-8 p-4">
      <div className=" text-center mb-6">
        <h2 className="text-2xl font-bold">Cold Blooded</h2>
        <Link to="/Back2School" className=" text-lg font-medium text-black underline">
        Collection Preview
          {/* <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2" /> */}
        </Link>
      </div>
      
    
      <div className=''>
        <ProductSection />
       </div>
    </section>

    <section className="relative w-screen mt-10 h-[500px] max-sm:h-[1000px] max-sm:bg-cover bg-cover bg-no-repeat" 
             style={{ backgroundImage: `url(${frame3})` }}>
      
      <div className="relative z-10 items-center justify-center text-white flex flex-col h-full p-8 md:p-16 max-w-full">
        <h1 className="text-[64px] max-sm:text-[50px] font-bold mb-4 uppercase">noir</h1>
        <p className="text-[32px] mb-6 text-center">The future is black, the attitude is limitless.</p>

        <a 
          href="/shop" 
          className="inline-flex max-sm:underline items-center text-white underline text-[32px] font-medium">
          Shop Collection
          <img src={arrowup} className="ml-2" />
        </a>
        </div>
    </section>

    <section className="my-8 p-4">
      <div className=" text-center mb-6">
        <h2 className="text-2xl font-bold">Noir</h2>
        <Link to="/Back2School" className=" text-lg font-medium text-black underline">
        Collection Preview
          {/* <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2" /> */}
        </Link>
      </div>
      
    
      <div className=''>
        <ProductSection />
       </div>
    </section>

    <Footer />
  </>
  );
};

export default HumanDiagrams;
