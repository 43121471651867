import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';

const OrderBulk = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [updatedPrice, setUpdatedPrice] = useState('');
  const [updatedQuantity, setUpdatedQuantity] = useState('');
  const { phonenumber } = useParams();

  // Add useEffect to fetch products when component mounts
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array means it runs once when component mounts

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        // `http://localhost:3005/admin/products`
        "https://humandiagrams-backend.onrender.com/admin/products"
      );
      setProducts(response.data.products);
      console.log(response.data.products);
    } catch (error) {
      console.log('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setUpdatedPrice(product.price);
    setUpdatedQuantity(product.number);
    setModalIsOpen(true);
  };

  const handleUpdateProduct = async () => {
    try {
      const updatedProduct = {
        price: updatedPrice,
        number: updatedQuantity,
      };
      const response = await axios.put(
        // `http://localhost:3005/admin/update/${selectedProduct._id}`, 
        `https://humandiagrams-backend.onrender.com/admin/update/${selectedProduct._id}`,
        updatedProduct);
      if (response.data.success) {
        alert('Product updated successfully!');
        setModalIsOpen(false);
        fetchProducts();
      }
    } catch (error) {
      console.log('Error updating product:', error);
      alert('Failed to update product');
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const response = await axios.delete(
        // `http://localhost:3005/admin/delete/${productId}`
        `https://humandiagrams-backend.onrender.com/admin/delete/${productId}`
      );
      if (response.data.success) {
        alert('Product deleted successfully!');
        fetchProducts();
      }
    } catch (error) {
      console.log('Error deleting product:', error);
      alert('Failed to delete product');
    }
  };

  const handleBulkOrder = async () => {
    try {
      const orderData = {
        phoneNumber: phonenumber,
        products: cart,
        totalAmount: cart.reduce((total, item) => total + item.price, 0),
      };
      const response = await axios.post('http://localhost:3005/saleagent/bulk-order', orderData);
      if (response.data.success) {
        alert('Bulk order placed successfully!');
        setCart([]);
      }
    } catch (error) {
      console.log('Error placing bulk order:', error);
      alert('Failed to place bulk order');
    }
  };

  return (
    <div className="p-4 max-sm:ml-0 ml-60 mt-20">
      <h1 className="text-2xl font-bold mb-4">View Clothes</h1>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <BounceLoader color="#4A90E2" loading={loading} size={100} />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {products.map((product) => (
            <div key={product._id} className="border p-4 rounded shadow-md">
              <div>
                <img src={product.image} />
              </div>
              <h2 className="text-lg font-bold">{product.title}</h2>
              <p>Price: ${product.price}</p>
              <button
                className="bg-blue-500 text-white px-2 py-1 rounded mt-2"
                onClick={() => handleEditProduct(product)}
              >
                Edit
              </button>
              <button
                className="bg-red-500 text-white px-2 py-1 rounded mt-2 ml-2"
                onClick={() => handleDeleteProduct(product._id)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}

      {modalIsOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg">
            <h2 className="text-lg font-bold">Edit Product</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700">Price</label>
                <input
                  type="number"
                  value={updatedPrice}
                  onChange={(e) => setUpdatedPrice(e.target.value)}
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Quantity</label>
                <input
                  type="number"
                  value={updatedQuantity}
                  onChange={(e) => setUpdatedQuantity(e.target.value)}
                  className="border p-2 w-full"
                />
              </div>
              <button
                type="button"
                onClick={handleUpdateProduct}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Update
              </button>
              <button
                type="button"
                onClick={() => setModalIsOpen(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded ml-2"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderBulk;