import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ProductSection = () => {
  const [products, setProducts] = useState([]);
  const [randomProducts, setRandomProducts] = useState([]);

  // Fetch products from backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
            // 'http://localhost:3005/admin/main/products'
            "https://humandiagrams-backend.onrender.com/admin/main/products"
        ); // Replace with your API endpoint
        const allProducts = response.data;

        // Randomize and pick 4 unique products
        const shuffled = allProducts.sort(() => 0.5 - Math.random());
        const uniqueProducts = shuffled.slice(0, 4);

        setProducts(allProducts);
        setRandomProducts(uniqueProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="grid grid-cols-4 max-sm:grid-cols-2 w-full">
      {randomProducts.map((product, index) => (
        <div key={index} className="">
          <img src={product.image} alt={product.name} className="p-3 w-[500px] h-[390px] max-sm:h-[200px]" />
        </div>
      ))}
    </div>
  );
};

export default ProductSection;
